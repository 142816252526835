/* eslint-disable no-restricted-globals */
import { precacheAndRoute } from 'workbox-precaching';

precacheAndRoute(self.__WB_MANIFEST);

const cacheName = 'mp3player';

self.addEventListener('fetch', function (event) {
    event.respondWith(
        // intercepting response for bundle.js since bundle.js may change and we need to replace it in our cahce
        event.request.url.includes('public/bundle.js')
            ? checkBundle(event.request) //if it is the bundle URL then use our custom function for handling the request
            : caches
                  .match(event.request) //if its not then do the use service-worker code:
                  .then(function (response) {
                      // other requests code
                  })
    );
});

// our custom function which does the magic:
function checkBundle(request) {
    return new Promise(function (resolve, reject) {
        // respondWith method expects a Promise
        caches.open(cacheName).then(function (cache) {
            //first lets check whether its in cache already or not
            // ignoreSearch parameter will ignore the query parameter while searching in cache, i.e., our cache busting timestmap
            cache.keys(request, { ignoreSearch: true }).then(function (keys) {
                if (keys.length === 0) {
                    // its not in cache so fetch it
                    return resolve(
                        fetch(request).then(function (response) {
                            if (
                                !response ||
                                (response.status !== 200 &&
                                    response.status !== 0)
                            ) {
                                return response;
                            }
                            cache.put(request, response.clone());
                            return response;
                        })
                    );
                }
                //it is in cache, so now we extract timestamp from current and cached URL and compare them
                const lastVersion = /bundle.js\?v=(.*)$/.exec(keys[0].url)[1],
                    curVersion = /bundle.js\?v=(.*)$/.exec(request.url)[1];

                if (lastVersion == curVersion)
                    // if timestamp is change that means no change in the resource
                    return resolve(cache.match(request)); //return the cached resource

                //bundle file has changed, lets delete it from cache first
                cache.delete(keys[0]);
                //now we fetch new bundle and serve it and store in cache
                var fetchRequest = request.clone();
                resolve(
                    fetch(fetchRequest).then(function (response) {
                        if (
                            !response ||
                            (response.status !== 200 && response.status !== 0)
                        ) {
                            return response;
                        }
                        cache.put(request, response.clone());
                        return response;
                    })
                );
            });
        });
    });
}

// Simpler
/*
import { precacheAndRoute } from 'workbox-precaching';

precacheAndRoute(self.__WB_MANIFEST);

self.addEventListener('fetch', function (event) {
    event.respondWith(fetch(event.request));
});

 */
